@import "../App.scss";

.navigationContainer {
  .nav-style {
    background-color: $primary-color;
    box-shadow: 0 0.5px 1px black;
  }

  .nav-link {
    color: $secondary-color !important;
  }

  .navbar-toggler {
    border: 0;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(239, 239, 239, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .brandName {
    font-family: $primary-font;
    font-size: min(6vw, 25px);
    color: $secondary-color;
  }

  .dropdown-menu.show {
    background-color: $secondary-color !important;
  }

  a.dropdown-item {
    color: $primary-color !important;
  }

  a.dropdown-item.active {
    background-color: transparent !important;
  }

  a.dropdown-item:active {
    background-color: transparent !important;
  }

  a:focus {
    color: white;
  }

  a.active {
    color: white !important;
  }
}
