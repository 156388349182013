@import "../App.scss";

.impressumContainer {
  .card {
    margin: 0 auto;

    h2 {
      font-size: 15px;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }

    h3 {
      font-size: 13px;
      font-weight: 400;
    }
  }
}
