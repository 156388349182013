@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Roboto+Condensed:wght@300&display=swap");

* {
  -webkit-overflow-scrolling: touch;
}

$primary-color: #8773a1;
$secondary-color: rgba(239, 239, 239, 0.8);

$primary-font: "Oswald", sans-serif;
$secondary-font: "Roboto Condensed", sans-serif;

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .card-body {
    #nachweis {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
      width: 300px;
      display: flex;
      margin: 25px auto;
    }
  }

  .contentWrap {
    flex: 1;

    .wrapper {
      padding-top: 100px;
    }

    .topic {
      color: $primary-color;
      font-variant: small-caps;
    }

    .center {
      margin: 0 auto;
    }

    .justify {
      text-align: justify;
    }

    .spacer {
      height: 70px;
      width: 100%;
    }

    a.link {
      cursor: pointer;
      color: black !important;
      text-decoration: underline dotted !important;
    }
  }
}
