@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Roboto+Condensed:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{-webkit-overflow-scrolling:touch}.appContainer{display:flex;flex-direction:column;min-height:100vh}.appContainer .card-body #nachweis{background-color:#8773a1 !important;border-color:#8773a1 !important;width:300px;display:flex;margin:25px auto}.appContainer .contentWrap{flex:1 1}.appContainer .contentWrap .wrapper{padding-top:100px}.appContainer .contentWrap .topic{color:#8773a1;font-feature-settings:"smcp";font-variant:small-caps}.appContainer .contentWrap .center{margin:0 auto}.appContainer .contentWrap .justify{text-align:justify}.appContainer .contentWrap .spacer{height:70px;width:100%}.appContainer .contentWrap a.link{cursor:pointer;color:#000 !important;-webkit-text-decoration:underline dotted !important;text-decoration:underline dotted !important}
*{-webkit-overflow-scrolling:touch}.appContainer{display:flex;flex-direction:column;min-height:100vh}.appContainer .card-body #nachweis{background-color:#8773a1 !important;border-color:#8773a1 !important;width:300px;display:flex;margin:25px auto}.appContainer .contentWrap{flex:1 1}.appContainer .contentWrap .wrapper{padding-top:100px}.appContainer .contentWrap .topic{color:#8773a1;font-feature-settings:"smcp";font-variant:small-caps}.appContainer .contentWrap .center{margin:0 auto}.appContainer .contentWrap .justify{text-align:justify}.appContainer .contentWrap .spacer{height:70px;width:100%}.appContainer .contentWrap a.link{cursor:pointer;color:#000 !important;-webkit-text-decoration:underline dotted !important;text-decoration:underline dotted !important}.navigationContainer .nav-style{background-color:#8773a1;box-shadow:0 .5px 1px #000}.navigationContainer .nav-link{color:rgba(239,239,239,.8) !important}.navigationContainer .navbar-toggler{border:0}.navigationContainer .navbar-toggler-icon{background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(239, 239, 239, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important}.navigationContainer .navbar-toggler:focus{box-shadow:none !important}.navigationContainer .brandName{font-family:"Oswald",sans-serif;font-size:min(6vw,25px);color:rgba(239,239,239,.8)}.navigationContainer .dropdown-menu.show{background-color:rgba(239,239,239,.8) !important}.navigationContainer a.dropdown-item{color:#8773a1 !important}.navigationContainer a.dropdown-item.active{background-color:transparent !important}.navigationContainer a.dropdown-item:active{background-color:transparent !important}.navigationContainer a:focus{color:#fff}.navigationContainer a.active{color:#fff !important}
*{-webkit-overflow-scrolling:touch}.appContainer{display:flex;flex-direction:column;min-height:100vh}.appContainer .card-body #nachweis{background-color:#8773a1 !important;border-color:#8773a1 !important;width:300px;display:flex;margin:25px auto}.appContainer .contentWrap{flex:1 1}.appContainer .contentWrap .wrapper{padding-top:100px}.appContainer .contentWrap .topic{color:#8773a1;font-feature-settings:"smcp";font-variant:small-caps}.appContainer .contentWrap .center{margin:0 auto}.appContainer .contentWrap .justify{text-align:justify}.appContainer .contentWrap .spacer{height:70px;width:100%}.appContainer .contentWrap a.link{cursor:pointer;color:#000 !important;-webkit-text-decoration:underline dotted !important;text-decoration:underline dotted !important}.impressumContainer .card{margin:0 auto}.impressumContainer .card h2{font-size:15px;font-weight:400;margin:0;padding:0}.impressumContainer .card h3{font-size:13px;font-weight:400}
*{-webkit-overflow-scrolling:touch}.appContainer{display:flex;flex-direction:column;min-height:100vh}.appContainer .card-body #nachweis{background-color:#8773a1 !important;border-color:#8773a1 !important;width:300px;display:flex;margin:25px auto}.appContainer .contentWrap{flex:1 1}.appContainer .contentWrap .wrapper{padding-top:100px}.appContainer .contentWrap .topic{color:#8773a1;font-feature-settings:"smcp";font-variant:small-caps}.appContainer .contentWrap .center{margin:0 auto}.appContainer .contentWrap .justify{text-align:justify}.appContainer .contentWrap .spacer{height:70px;width:100%}.appContainer .contentWrap a.link{cursor:pointer;color:#000 !important;-webkit-text-decoration:underline dotted !important;text-decoration:underline dotted !important}.projektContainer{width:min(95vw,600px);margin:70px auto;display:flex;flex-direction:column;grid-gap:25px;gap:25px}.projektContainer a{color:#8773a1}.projektContainer .progress{margin-bottom:10px;height:22px}.projektContainer .completedProjects{display:flex;flex-direction:column-reverse;flex-wrap:wrap}.projektContainer #infoBox{width:min(90vw,600px);margin-top:50px}.projektContainer h1{color:#8773a1;text-align:center;margin-top:100px}.projektContainer .card{width:min(90vw,500px);margin:0 auto;margin-top:20px !important}.projektContainer .card .card-img-top{object-fit:cover;object-position:center;height:350px}
*{-webkit-overflow-scrolling:touch}.appContainer{display:flex;flex-direction:column;min-height:100vh}.appContainer .card-body #nachweis{background-color:#8773a1 !important;border-color:#8773a1 !important;width:300px;display:flex;margin:25px auto}.appContainer .contentWrap{flex:1 1}.appContainer .contentWrap .wrapper{padding-top:100px}.appContainer .contentWrap .topic{color:#8773a1;font-feature-settings:"smcp";font-variant:small-caps}.appContainer .contentWrap .center{margin:0 auto}.appContainer .contentWrap .justify{text-align:justify}.appContainer .contentWrap .spacer{height:70px;width:100%}.appContainer .contentWrap a.link{cursor:pointer;color:#000 !important;-webkit-text-decoration:underline dotted !important;text-decoration:underline dotted !important}.footerContainer{height:150px;width:100%;background-color:#8773a1;margin-top:30px}
