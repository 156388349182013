@import "../App.scss";

.projektContainer {
  width: min(95vw, 600px);
  margin: 70px auto;
  display: flex;
  flex-direction: column;
  gap: 25px;

  a {
    color: $primary-color;
  }
  .progress {
    margin-bottom: 10px;
    height: 22px;
  }

  .completedProjects {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  #infoBox {
    width: min(90vw, 600px);
    margin-top: 50px;
  }

  h1 {
    color: $primary-color;
    text-align: center;
    margin-top: 100px;
  }

  .card {
    width: min(90vw, 500px);
    margin: 0 auto;
    margin-top: 20px !important;
    .card-img-top {
      object-fit: cover;
      object-position: center;
      height: 350px;
    }
  }
}
